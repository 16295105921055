import request from './request'

// 注册客户
export const RegisterUserApi = (params) => request.post('/user/v1/pandora/business/uc/user/registerUser', params)

// 获取短信验证码
export const GetSmsApi = (params) => request.post('/user/v1/pandora/business/uc/sms/getSms', params)

// 登录
export const LoginApi = (params) => request.post('/user/v1/pandora/business/uc/login/login', params)

// 登出
export const LogoutApi = () => request.post('/user/v1/pandora/business/uc/login/logout')


// 编辑用户信息
export const EditUserApi = (params) => request.post('/user/v1/pandora/business/uc/user/editUser', params)


//绑定客户手机
export const BindMobileApi = (params) => request.post('/user/v1/pandora/business/uc/user/bindMobile', params)


//修改客户密码
export const UpdatePasswordApi = (params) => request.post('/user/v1/pandora/business/uc/user/updatePassword', params)


// 获取邮箱验证码
export const GetEmailApi = (params) => request.post('/user/v1/pandora/business/uc/email/getEmail', params)

//绑定客户邮箱
export const BindEmaileApi = (params) => request.post('/user/v1/pandora/business/uc/user/bindEmail', params)

//忘记密码功能
export const ResetPasswordApi = (params) => request.post('/user/v1/pandora/business/uc/user/resetPassword', params)

//手机号校验
export const CheckMobileApi = (params) => request.post('/user/v1/pandora/business/uc/sms/checkMobile', params)

//根据用户id查询产品列表
export const GetProductByUserIdApi = () => request.get('/user/v1/pandora/business/uc/product/getProductByUserId')

export const GetRecentLoginInfo = () => request.get('/user/v1/pandora/business/uc/user/queryUserRecentLoginInfo')

export const GetUserInfoById = (id) => request.get(`/user/v1/pandora/business/uc/user/queryUserById?id=${id}`)