import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, message, Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
import close from './assets/img/close.png'
import open from './assets/img/open.png'
import { LoginApi, GetSmsApi, RegisterUserApi, ResetPasswordApi, CheckMobileApi, GetUserInfoById } from '../request/api'
import styles from './assets/index.module.less';
import { useWatch } from 'antd/lib/form/Form';

const checkPwd = (e) => {
  const isSpecial = (a) => {
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
    if (reg.test(a)) {
      return true;
    } else {
      return false;
    }
  }

  let s = e;
  if (isSpecial(s)) {
    console.log('密码中允许出现数字、大写字母、小写字母，但至少包含其中3种')
    return '3';
  }
  return '2'
}

function AccountLogin() {
  const history = useHistory()
  const [showPwd, setShowPwd] = useState('password')
  const [showPwd1, setShowPwd1] = useState('password')
  const [showPwd2, setShowPwd2] = useState('password')
  const [remoteLogin, setRemoteLogin] = useState(false);
  const [loginType, setLoginType] = useState(0);
  const [canLogin, setCan] = useState(false);
  const [form] = Form.useForm();
  const passwordValue = useWatch('password', form);
  const userName = useWatch('userName', form);
  const [smsValidProps, setSmsValidProps] = useState({});

  const searches = useMemo(() => new URLSearchParams(window.location.search), []);

  useEffect(() => {
    if (searches.get('loginType')) {
      setLoginType(parseInt(searches.get('loginType')))
    }
  }, [searches]);

  useEffect(() => {
    setRemoteLogin(false);
  }, [userName]);
  
  const setSmsError = (val) => {
    setSmsValidProps({
      validateStatus: 'error',
      help: val
    })
  }

  const clearSmsError = () => setSmsValidProps({})

  const onCheckPhone = () => {
    CheckMobileApi({
      mobile: mobile
    }).then(res => {
      console.log(res)
      if (!res.result) {
        message.error(res.msg)
      }
    })
  }

  // 登录方法
  const onFinish = (values) => {
    if (!canLogin) return;
    const fn = [() => LoginApi({
      userName: values.userName,
      password: values.password,
      loginWay: '1',
      userType: '2',
      loginTerminal: '1',
      verificationCode: remoteLogin ? values.verificationCode : undefined
    }), () => LoginApi({
      mobile: values.mobile,
      verificationCode: values.verificationCode,
      password: remoteLogin ? values.password : undefined,
      loginWay: '2',
      userType: '2',
      loginTerminal: '1'
    })]
    if (loginType <= 1) {
      fn[loginType]().then((res) => {
        console.log('res:', res);
        if (res.result) {
          message.success('登录成功')
          //存储用户登录数据
          localStorage.setItem('userToken', res.data['userToken'])
          localStorage.setItem('userCode', res.data.id)
          localStorage.setItem('userName', res.data.userName || '')
          localStorage.setItem('realName', res.data.realName || '')
          localStorage.setItem('terminalType', res.data.terminalType)
          localStorage.setItem('userType', res.data.userType)
          localStorage.setItem('mobile', res.data.mobile || '')
          localStorage.setItem('email', res.data.email || '')
          localStorage.setItem('loginWay', res.data.loginWay)
          localStorage.setItem('loginTime', res.data.loginTime)
          localStorage.setItem('photoUrl', res.data.photoUrl || '')
          localStorage.setItem('registerTime', res.data.registerTime)
          localStorage.setItem('pwdLevel', res.data.pwdLevel)
          //跳转到用户中心页面
          history.push('/myInfo')
        } else {
          if (res.msg === 'remoteLogin') {
            setRemoteLogin(true);
            message.info(`异地登陆，请${loginType ? '输入密码' : '获取验证码'}。`)
          } else {
            message.error(res.msg)
          }
        }
      })
    }
    if (loginType === 2) {
      RegisterUserApi({
        userName: values.mobile,
        password: values.password,
        verificationCode: values.verificationCode,
        pwdLevel: checkPwd(values.password)
      }).then((res) => {
        console.log('res:', res);
        if (res.result) {
          message.success(res.data)
          //跳转到登录页面
          setLoginType(0)
        } else {
          message.error(res.msg)
        }
      })
    }
    if (loginType === 3) {
      ResetPasswordApi({
        password: values.password,
        mobile: values.mobile,
        verificationCode: values.verificationCode,
        pwdLevel: checkPwd(values.password)
      }).then(res => {
        if (res.result) {
          if (searches.get('redirect')) {
            window.location.replace(decodeURIComponent(searches.get('redirect')))
          } else {
            message.success(res.data)
            //跳转到登录页面
            setLoginType(0)
          }
        } else {
          message.error(res.msg)
        }
      })
    }

  }

  const isPhone = (value) => /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)

  const saveFormData = (e) => {
    let value = e.target.value.replace(/(^\s*)|(\s*$)/g, '');
    setMobile(value)
    if (value) {
      let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (reg.test(value)) {
        setMobile(value)
      } else {

      }
    } else {
      // message.error('请输入手机号！');
    }
  }

  const canSave = () => {
    const all = form.getFieldsValue();
    if (loginType === 0) {
      if (!isPhone(all.userName) || !all.password) {
        setCan(false)
        return;
      }
    }
    if (loginType === 1) {
      if (!isPhone(all.mobile) || !all.verificationCode?.trim()) return setCan(false);
    }
    if (loginType === 2) {
      if (
        isPhone(all.mobile) &&
        all.verificationCode?.trim() &&
        /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$){8,16}/.test(all.password) &&
        /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$){8,16}/.test(all.password1) &&
        all.agree
      ) {
      } else {
        return setCan(false)
      }
    }
    if (loginType === 3) {
      if (
        isPhone(all.mobile) &&
        all.verificationCode?.trim() &&
        /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$){8,16}/.test(all.password)
      ) {
      } else {
        return setCan(false)
      }
    }
    setCan(true);
  }

  useEffect(() => {
    form.resetFields();
    setShowPwd('password')
    setShowPwd1('password')
    setShowPwd2('password')
    setTimeout(canSave, 100)
    setRemoteLogin(false)
  }, [loginType])

  const showPwdFn = (name, showPwd) => {
    let type = 'password';
    if (showPwd === 'text') {
      type = 'password'
    } else {
      type = 'text'
    }
    [setShowPwd, setShowPwd1, setShowPwd2][name](type);
  }
  let [timer, setTimer] = useState(60);
  let [timer1, setTimer1] = useState(60);

  const startTimer = (timer, setTimer) => {
    setTimer(--timer)
    const t = setInterval(() => {
      if (timer <= 0) {
        clearInterval(t);
        setTimer(60)
      } else {
        setTimer(--timer)
      }
    }, 1000)
  }

  const getSmsCode = () => {
    const name = loginType === 0 ? userName : mobile
    if (loginType === 0 && ( !isPhone(name) || timer1 < 60)) return;
    if (loginType === 1 && ( !isPhone(name) || timer < 60)) return;
    GetSmsApi({
      mobile: name,
      userType: '2',
      smsType: loginType <= 1 ? 1 : loginType, // 1: 登录 2: 注册 3: 重置
    }).then((res) => {
      console.log('res:', res);
      if (res.result) {
        message.success(res.data)
        loginType === 0 ? startTimer(timer1, setTimer1) : startTimer(timer, setTimer)
      } else {
        message.error(res.msg)
      }
    })
  }

  useEffect(() => {})

  const AccountLogin =
    <div style={{ minHeight: '200px' }}>
      <div style={{ marginTop: '16px' }}>
        <Form.Item name="userName"
          style={{ padding: '0 40px' }}
          getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              required: true,
              message: '请输入手机号!',
            },
            {
              pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
              message: '请输入正确的手机号',
              validateTrigger: ['onBlur']
            }
          ]}
        >
          <Input placeholder="请输入手机号" allowClear style={{ flex: '1 1 auto' }} className="custom-input" autoComplete="off"
            onChange={saveFormData} prefix={<img className='input-prefix-img' src={require("./assets/img/SketchPng0c30b7e64b8fc65bab9a31034211dbad6de1e1d9701195e22cc4879349116d6d.png")} />} />
        </Form.Item>
        <Form.Item name="password" style={{ padding: '0 40px' }} validateTrigger={['onBlur']}
          getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
          rules={[
            {
              required: true,
              message: '请输入密码!',
              validateTrigger: ['onChange']
            },
            {
              type: 'string',
              max: 16,
              min: 8,
              required: true,
              pattern: /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$){8,16}/,
              message: '请输入8-16位密码，数字、字母和符号至少包含两种',
              validateTrigger: ['onBlur']
            }
          ]}
        >
          <Input type={showPwd} className="custom-input" placeholder="请输入密码" size='large'
            prefix={<img className='input-prefix-img' src={require("./assets/img/SketchPng50b9dfe1abf6d1f4bd1ed3a17906e3f9d6a17f913ee8a86d1c8b8902b76dcf5f.png")} />}
            suffix={<img alt=''
              src={showPwd == 'text' ? open : close} style={{ 'width': '24px' }} onClick={() => showPwdFn(0, showPwd)}
            />}
          />
        </Form.Item>
        {remoteLogin &&
        <Form.Item name="verificationCode" style={{ padding: '0 40px' }} {...smsValidProps}
        rules={[
          {
            required: true,
            message: '请输入短信中的验证码!',
          },
        ]}
      >
        <Input placeholder="请输入短信中的验证码"
          onChange={() => setSmsValidProps({})}
          prefix={<img className='input-prefix-img' src={require("./assets/img/keyboard.png")} />}
          suffix={<span className={`${styles['text_7']} ${timer1 < 60 ? 'getcode-disable' : 'getcode'}`} onClick={getSmsCode}> {timer1 == 60 ? '获取验证码' : `${timer1}s 后重新获取`}   </span>} size='large' className="custom-input"
        />
      </Form.Item>
      }
      {timer1 < 60 && <span className='getcode-footer'>短信已发送！请输入短信中的动态密码</span>}
      
      </div>

      <div className={`${styles['group_3']} flex-row justify-between`}>
        <div className={`${styles['image-text_11']} flex-row justify-between`}>
          {/* <div className={`${styles['block_2']} flex-col`} />
          <span className={`${styles['text-group_4']}`}>一周内自动登录</span> */}
        </div>
        <span className={`${styles['text_7']} forget-password`} onClick={() => setLoginType(3)}>忘记密码?</span>
      </div>
    </div>

  const [mobile, setMobile] = useState('')



  const SMSLogin = <>
    <div style={{ marginTop: '16px', minHeight: '184px' }}>
      <Form.Item name="mobile" onChange={saveFormData} style={{ padding: '0 40px' }} validateTrigger={['onBlur', 'onChange']}  getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
        rules={[
          {
            required: true,
            message: '请输入手机号!',
          },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号',
            validateTrigger: ['onBlur']
          }
        ]}
      >
        <Input placeholder="请输入手机号" allowClear className="custom-input" size='large' prefix={<img className='input-prefix-img' src={require("./assets/img/phone.png")} />}
        />
      </Form.Item>


      <Form.Item name="verificationCode" style={{ padding: '0 40px' }} {...smsValidProps}
        rules={[
          {
            required: true,
            message: '请输入短信中的验证码!',
          },
        ]}
      >
        <Input placeholder="请输入短信中的验证码"
          onChange={() => setSmsValidProps({})}
          prefix={<img className='input-prefix-img' src={require("./assets/img/keyboard.png")} />}
          suffix={<span className={`${styles['text_7']} ${!isPhone(mobile) || timer < 60 ? 'getcode-disable' : 'getcode'}`} onClick={getSmsCode}> {timer == 60 ? '获取验证码' : `${timer}s 后重新获取`}   </span>} size='large' className="custom-input"
        />
      </Form.Item>

      {timer < 60 && <span className='getcode-footer'>短信已发送！请输入短信中的动态密码</span>}
      {
        remoteLogin && <Form.Item name="password" style={{ padding: '0 40px' }} validateTrigger={['onBlur']}
        rules={[
          {
            required: true,
            message: '请输入密码!',
            validateTrigger: ['onChange']
          },
          {
            type: 'string',
            max: 16,
            min: 8,
            required: true,
            pattern: /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$){8,16}/,
            message: '请输入8-16位密码，数字、字母和符号至少包含两种',
            validateTrigger: ['onBlur']
          }
        ]}
      >
        <Input type={showPwd} className="custom-input" placeholder="请输入密码" size='large'
          prefix={<img className='input-prefix-img' src={require("./assets/img/SketchPng50b9dfe1abf6d1f4bd1ed3a17906e3f9d6a17f913ee8a86d1c8b8902b76dcf5f.png")} />}
          suffix={<img alt=''
            src={showPwd == 'text' ? open : close} style={{ 'width': '24px' }} onClick={() => showPwdFn(0, showPwd)}
          />}
        />
      </Form.Item>
      }
      {
        remoteLogin && <div className={`${styles['group_3']} flex-row justify-between`}>
        <div className={`${styles['image-text_11']} flex-row justify-between`}>
          {/* <div className={`${styles['block_2']} flex-col`} />
          <span className={`${styles['text-group_4']}`}>一周内自动登录</span> */}
        </div>
        <span className={`${styles['text_7']} forget-password`} onClick={() => setLoginType(3)}>忘记密码?</span>
      </div>
      }

    </div>
  </>

  const Register = <>
    <div style={{ marginTop: '16px' }} className="register">
      <Form.Item name="mobile" onChange={saveFormData} style={{ padding: '0 40px' }} validateTrigger={['onBlur', 'onChange']}
        getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
        rules={[
          {
            required: true,
            message: '请输入手机号!',
          },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号',
            validateTrigger: ['onBlur']
          }
        ]}
      >
        <Input placeholder="请输入手机号" allowClear className="custom-input" size='large' prefix={<img className='input-prefix-img' src={require("./assets/img/phone.png")} />}
          onBlur={onCheckPhone} />
      </Form.Item>


      <Form.Item name="verificationCode" style={{ padding: '0 40px' }}
        rules={[
          {
            required: true,
            message: '请输入短信中的验证码!',
          },
        ]}
      >
        <Input placeholder="请输入验证码"
          prefix={<img className='input-prefix-img' src={require("./assets/img/keyboard.png")} />}
          suffix={<span className={`${styles['text_7']} ${!isPhone(mobile) || timer < 60 ? 'getcode-disable' : 'getcode'}`} onClick={getSmsCode}> {timer == 60 ? '获取验证码' : `${timer}s 后重新获取`}   </span>} size='large' className="custom-input"
        />
      </Form.Item>

      <Form.Item name="password" style={{ padding: '0 40px' }} validateTrigger={['onBlur']}
        getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
        rules={[
          {
            type: 'string',
            max: 16,
            min: 8,
            required: true,
            pattern: /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$){8,16}/,
            message: '请输入8-16位密码，数字、字母和符号至少包含两种',
            validateTrigger: ['onBlur']
          }
        ]}
      >
        <Input type={showPwd1} className="custom-input" placeholder="请输入密码(8-16个字符)" size='large' maxLength={16}
          prefix={<img className='input-prefix-img' src={require("./assets/img/SketchPng50b9dfe1abf6d1f4bd1ed3a17906e3f9d6a17f913ee8a86d1c8b8902b76dcf5f.png")} />}
          suffix={<img alt=''
            src={showPwd1 == 'text' ? open : close} style={{ 'width': '24px' }} onClick={() => showPwdFn(1, showPwd1)}
          />}
        />
      </Form.Item>

      <Form.Item name="password1" style={{ padding: '0 40px', marginBottom: '8px' }}
        rules={[
          {
            validator: (_, value, callback) => {
              if (!/((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$){8,16}/.test(value)) {
                return callback('请输入8-16位密码，数字、字母和符号至少包含两种')
              }
              value === passwordValue ? callback() : callback("密码不一致")
            }
          }
        ]}
      >
        <Input type={showPwd2} className="custom-input" placeholder="确认密码" size='large' maxLength={16}
          prefix={<img className='input-prefix-img' src={require("./assets/img/SketchPng50b9dfe1abf6d1f4bd1ed3a17906e3f9d6a17f913ee8a86d1c8b8902b76dcf5f.png")} />}
          suffix={<img alt=''
            src={showPwd2 == 'text' ? open : close} style={{ 'width': '24px' }} onClick={() => showPwdFn(2, showPwd2)}
          />}
        />
      </Form.Item>

      <Form.Item name="agree" valuePropName="checked" style={{ padding: '0 40px' }}>
        <span>
          <Checkbox />&nbsp;
          <span className={`${styles['text_7']} text-color`}>我已阅读并同意&nbsp;</span>
          <span className={`${styles['text_8']} text-active-color`}>迈思科技用户协议</span>
          <span className={`${styles['text_9']} text-color`}>&nbsp;和&nbsp;</span>
          <span className={`${styles['text_10']} text-active-color`}>隐私政策</span>
        </span>
      </Form.Item>
    </div>
  </>


  const LoginFooter = <>
    <div className={`other-way`}>
      <span className={`${styles['text_10']}`}>其他方式</span>
    </div>
    <div className={`${styles['group_4']} flex-row justify-between`}>
      <div className={`${styles['group_2']} flex-row`}>
        <div className={`${styles['image-text_12']} flex-row justify-between`}>
          <div className={`${styles['block_5']} flex-col`}>
            <div className={`${styles['box_10']} flex-col`} />
          </div>
          <span className={`${styles['text-group_5']}`}>微信登录</span>
        </div>
      </div>
      <div className={`${styles['image-wrapper_1']} flex-col`}>
        <img
          className={`${styles['image_2']}`} alt=''
          src={
            require('./assets/img/SketchPng108f5ca70e0e18a21e8077670f9d3bb78b75b7eece8d952fbae7f5b990e69652.png')
          }
        />
      </div>
    </div>
  </>

  const RegisterFooter = <>
    <div style={{ margin: '0 40px', textAlign: 'right' }}><span className='text-color'>已有账户？</span><span className='text-active-color pointer' onClick={() => setLoginType(0)}>前去登录</span></div>
  </>

  const ResetPassword = <div style={{ marginTop: '16px', height: '284px', position: 'relative' }} className="register">
    <Form.Item name="mobile" onChange={saveFormData} style={{ padding: '0 40px' }} validateTrigger={['onBlur', 'onChange']}
      getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
      rules={[
        {
          required: true,
          message: '请输入手机号!',
        },
        {
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '请输入正确的手机号',
          validateTrigger: ['onBlur']
        }
      ]}
    >
      <Input placeholder="请输入手机号" allowClear className="custom-input" size='large' prefix={<img src={require("./assets/img/phone.png")} className='input-prefix-img' />}
      />
    </Form.Item>


    <Form.Item name="verificationCode" style={{ padding: '0 40px' }}
      rules={[
        {
          required: true,
          message: '请输入短信中的验证码!',
        },
      ]}
    >
      <Input placeholder="请输入验证码"
        prefix={<img src={require("./assets/img/keyboard.png")} className='input-prefix-img' />}
        suffix={<span className={`${styles['text_7']} ${!isPhone(mobile) || timer < 60 ? 'getcode-disable' : 'getcode'}`} onClick={getSmsCode}> {timer == 60 ? '获取验证码' : `${timer}s 后重新获取`}   </span>} size='large' className="custom-input"
      />
    </Form.Item>

    <Form.Item name="password" style={{ padding: '0 40px' }} validateTrigger={['onBlur']}
      getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
      rules={[
        {
          type: 'string',
          max: 16,
          min: 8,
          required: true,
          pattern: /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$){8,16}/,
          message: '请输入8-16位密码，数字、字母和符号至少包含两种',
          validateTrigger: ['onBlur']
        }
      ]}
    >
      <Input type={showPwd1} className="custom-input" placeholder="请输入新密码(8-16个字符)" size='large' maxLength={16} autoComplete="new-password"
        prefix={<img className='input-prefix-img' src={require("./assets/img/SketchPng50b9dfe1abf6d1f4bd1ed3a17906e3f9d6a17f913ee8a86d1c8b8902b76dcf5f.png")} />}
        suffix={<img alt=''
          src={showPwd1 == 'text' ? open : close} style={{ 'width': '24px' }} onClick={() => showPwdFn(1, showPwd1)}
        />}
      />
    </Form.Item>
  </div>

  const handleResetBack = () => {
    const redirect = searches.get('redirect');
    if (redirect) {
      window.location.replace(decodeURIComponent(redirect))
    } else {
      setLoginType(0);
    }
  }

  return (
    <div className={`${styles.page}`}>
      <div className={`${styles['left-container']}`}>
        <img
          className={`${styles['image_1']}`} alt=''
          src={
            require('./assets/img/SketchPng6cdd754a54ac0eafe8e6ebe86d338200f938c66ffad4061a85c9a5c2a69b7f2e.png')
          }
        />
        <div style={{ flex: '1 1 auto' }}></div>
        <div>
          <p className={`${styles['text_1']} ${styles['slogan']}`}>迈思科技用户中心上线啦！</p>
          <p className={`${styles['text_2']}`}>
            北京迈思发展科技有限责任公司是一家集软件开发、智能硬件研究与销售服务一体的高科技企业。公司专注于成为建筑钢筋数字化产品的平台服务商。
          </p>
        </div>
        <div className={`${styles['box_1']} flex-col`} />
        <div style={{ flex: '1 1 auto' }}></div>
        <div className={`${styles['text-group_7']} flex-col justify-between`}>
          <span className={`${styles['text_3']}`}> <a href='http://www.rmes.cn/' target="_blank">关于我们</a>&nbsp;｜&nbsp;<a href='mailto:service@rmes.cn' target="_blank">联系我们</a>&nbsp;｜&nbsp;<a href='/html/agreement.html' target="_blank">用户协议</a>&nbsp;｜&nbsp;<a href='/html/privacy.html' target="_blank">隐私政策</a></span>
          <span className={`${styles['text_4']}`}>@RMES Company Limited 2018-2021 All Rights Reserved. 北京迈思发展科技有限责任公司 京ICP备14009982号-3</span>
        </div>
      </div>
      <div className={`${styles['right-container']}`}>
        <Form
          name="basic"
          initialValues={{
            remember: false,
          }}
          form={form}
          onValuesChange={canSave}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className={`${styles['box_2']} flex-col`}>
            <div className={`${styles['box_3']} flex-col`}>
              {[0, 1].includes(loginType) && <>
                <div className={`${styles['text-wrapper_6']} flex-row justify-between`}>
                  <span className={`${styles['text_6']} ${loginType === 0 && 'login-active'}`} onClick={() => setLoginType(0)}>账号登录</span>
                  <span className={`${styles['text_6']} ${loginType === 1 && 'login-active'}`} onClick={() => setLoginType(1)}>短信登录</span>
                </div>
              </>}
              {loginType == 2 && <div className={`${styles['text-wrapper_6']} flex-row justify-between`}>
                <span className={`${styles['text_6']}`} style={{ flex: '1 1 auto' }}>立即注册</span>
              </div>}
              {loginType == 3 && <div className={`${styles['text-wrapper_6']} flex-row justify-between`}>
                <span className={`${styles['text_6']}`} style={{ flex: '1 1 auto', cursor: 'unset' }}>
                  重置密码
                  <img src={require('./assets/img/back.png')} alt='' className="back-icon" onClick={handleResetBack} />
                </span>
              </div>}

              {loginType === 0 && AccountLogin}
              {loginType === 1 && SMSLogin}
              {loginType === 2 && Register}
              {loginType === 3 && ResetPassword}

              <div>
                {[0, 1].includes(loginType) && <Form.Item>
                  <button type="submit" className={`login-button ${canLogin ? '' : 'login-button-disable'}`}>
                    登录
                  </button>
                  <button className='register-button' onClick={() => setLoginType(2)}
                  >
                    立即注册
                  </button>
                </Form.Item>}
                {
                  loginType === 2 && <button type="submit" className={`login-button ${canLogin ? '' : 'login-button-disable'}`}>
                    立即注册
                  </button>
                }
                {
                  loginType === 3 && <button type="submit" className={`login-button ${canLogin ? '' : 'login-button-disable'}`}>
                    重置密码
                  </button>
                }
              </div>
              {/* {[0, 1].includes(loginType) && LoginFooter} */}
              {2 == loginType && RegisterFooter}
            </div>
          </div>
        </Form >
      </div>
    </div >
  );
}
export default AccountLogin;
