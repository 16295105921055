import axios from 'axios'
import ConfigPath from '../../ConfigPath';
// 配置项
const axiosOption = {
  // baseURL: 'https://uc-api-test.rmes.cn/user/',
  baseURL: ConfigPath.path,
  // baseURL: '/user',
  // baseURL: 'https://uc-api-test.rmes.cn/user/',
  timeout: 5000
}

// 创建一个单例
const instance = axios.create(axiosOption);

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  let userToken = localStorage.getItem('userToken')
  // let userCode = localStorage.getItem('userCode')
  // let userType = localStorage.getItem('userType')
  if (userToken) {
    config.headers = {
      'userToken': userToken,
      // 'userCode': userCode,
      // 'userType': userType
    }
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response.data;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default instance;
