import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import UserCenter from './view/user_center/UserCenter.jsx'

import AccountLogin from './view/account_login/AccountLogin.jsx'

import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd'
class App extends Component {
    render() {
        return (
            <ConfigProvider locale={zhCN}>
                <Router>
                    <div>
                        <Switch>
                            <Route exact path="/myInfo" component={UserCenter} />
                            <Route exact path="/accountLogin" component={AccountLogin} />
                            <Redirect from="/" to="/accountLogin" />
                        </Switch>
                    </div>
                </Router>
            </ConfigProvider>

        )
    }
}

export default App
