import React, { useEffect, useState, useCallback } from "react";
import { Modal, message } from "antd";
import Cropper from "react-easy-crop";
import getCroppedImg from './cropImage'
import { EditUserApi } from "../request/api";
import ConfigPath from '../../ConfigPath';
function base64ToFile(base64, fileName) {
  const arr = base64.split(",");
  const type = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type });
}

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
  if (!isJpgOrPng) {
    message.error('只能上传png、jpg和jpeg格式的图像!');
  }
  return isJpgOrPng;
};


const Output = ({ croppedArea, src }) => {
  const scale = 100 / croppedArea.width;
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: "100%",
    height: "auto",
  };

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
  };

  return (
    <div className="output" style={{ paddingBottom: `${100}%` }}>
      <img src={src} alt="" style={imageStyle} />
    </div>
  );
};

function ModifyPhoto(props) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onChange = (e) => {
    const file = new FileReader();
    file.onload = () => {
      setFile(file.result);
    };
    if (e.target.files[0]) {
      file.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    setCroppedArea(null);
  }, [file]);

  useEffect(() => {
    setCroppedArea(null);
    setFile(undefined);
    setCroppedAreaPixels(null);
  }, [props.open])

  const handleDrag = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          var file = ev.dataTransfer.items[i].getAsFile();
          return onChange({ target: { files: [file] } })
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        return onChange({ target: { files: [ev.dataTransfer.files[i]] } })
      }
    }
  };

  const handleOk = async () => {
    if (!file) return props.handleCancel();
    if (document.getElementById("file").files[0]) {
      const file = document.getElementById("file").files[0];
      const res = beforeUpload(file);
      if (!res) return;
    }
    setLoading(true);
    try {
      const base64 = await getCroppedImg(file, croppedAreaPixels);
      console.log(base64);
      const form = new FormData();
      const imgFile = base64ToFile(base64, `${new Date().valueOf()}-${Math.floor(Math.random() * 10000)}.jpeg`)
      form.append('file', imgFile)
      const res = await fetch(`${ConfigPath.path}/user/v1/pandora/business/uc/file/uploadFile`, {
        method: 'POST',
        body: form,
        headers: {
          "userToken": localStorage.getItem("userToken")
        }
      })

      if (res && res.ok) {
        const data = await res.json();
        console.log(data)
        await EditUserApi({
          photoUrl: data.data
        })
        setLoading(false);
        localStorage.setItem('photoUrl', base64)
        props.handleOk(base64);
      }
    } catch (e) {
      console.error(e);
      message.error("上传失败")
      setLoading(false)
    }
  };

  return (
    <Modal
      title="修改头像"
      open={props.open}
      width={432}
      onCancel={props.handleCancel}
      onOk={handleOk}
      destroyOnClose
      okText="应 用"
      confirmLoading={loading}
    >
      <div
        style={{
          overflow: "auto",
        }}
      >
        <div className="upload-container">
          {file && (
            <Cropper
              image={file}
              aspect={1}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              onCropAreaChange={(croppedArea) => {
                setCroppedArea(croppedArea);
              }}
            />
          )}
          <label style={{ textAlign: 'center' }} onDrop={handleDrag} onDragOver={e => { e.stopPropagation(); e.preventDefault() }}>
            {!file && (
              <>
                <p style={{ marginBottom: '.5em' }}>点击或拖拽文件到此处上传</p>
                <p style={{ color: "rgba(35,48,65,0.45)" }}>
                  支持 .JPG .PNG 图片格式
                </p>
              </>
            )}

            <input
              type="file"
              name="file"
              id="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={onChange}
            />
          </label>
        </div>


        <div style={{ float: "right", position: "relative", width: "80px" }}>
          {file && croppedArea ? (
            <div>
              <Output src={file} croppedArea={croppedArea} />
            </div>
          ) : (
            <img
              src={require("./assets/img/header.jpg")}
              alt=""
              style={{ width: 80 }}
            ></img>
          )}
          <p style={{ opacity: 0.5, textAlign: "center" }}>头像预览</p>
        </div>
        {file && <label className="reupload" htmlFor="file">重新上传</label>}
      </div>
    </Modal>
  );
}
export default ModifyPhoto;
