import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, message, Modal, Tabs } from 'antd';
import moment from 'moment'
import { EditUserApi, LogoutApi, GetSmsApi, BindMobileApi, UpdatePasswordApi, GetEmailApi, BindEmaileApi, GetProductByUserIdApi, GetRecentLoginInfo, GetUserInfoById, ResetPasswordApi } from '../request/api'
import defaultPhoto from './assets/img/header.jpg'
import binded from './assets/img/binded.png'
import unbound from './assets/img/unbound.png'
import styles from './assets/index.module.less';
import ModifyPhotoNew from './ModifyPhotoNew'

const isPhone = (value) => /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)

const labelControl = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}

function ModifyUserNew(props) {
  const [form] = Form.useForm();
  const handleOk = async () => {
    const e = await form.validateFields();
    const { result, data, msg } = await EditUserApi(e);
    if (result) {
      message.success(data)
      localStorage.setItem('realName', e.realName);
      props.handleOk(e);
      // setOpen(false);
    } else {
      message.error(msg)
    }
  }

  useEffect(() => {
    if(props.open) {
        form.setFieldsValue({realName:props.realName || undefined});
    }
  }, [props.open, form])


  return (
    <Modal title="编辑用户信息" open={props.open} onCancel={props.handleCancel} onOk={handleOk}>
        <Form form={form} colon={false} name="control-name" autoComplete='off' {...labelControl} >
        <Form.Item label="姓名" name="realName" required rules={[{ required: true, message: '请输入姓名' },{ max: 20, message: '姓名不能超过20个字符' }]}>
        <Input name='name' className="user-center-input" placeholder='请输入姓名' maxLength={20}></Input>
        </Form.Item>
      </Form>
    </Modal>
  )
}

function BindPhone(props) {
  const [form] = Form.useForm();
  const [phoneStauts, setPhoneStatus] = useState(false);

  const [smsValidProps, setSmsValidProps] = useState({});

  // TODO: 设置验证码的错误状态
  const setSmsError = (val) => {
    setSmsValidProps({
      validateStatus: 'error',
      help: val
    })
  }

  const clearSmsError = () => setSmsValidProps({})


  const handleOk = async () => {
    const e = await form.validateFields();
    const { result, data, msg } = await BindMobileApi(e);
    if (result) {
      message.success(data)
      localStorage.setItem('mobile', e.mobile);
      props.handleOk(e);
      // setOpen(false);
    } else {
      message.error(msg)
    }
  }

  let [timer, setTimer] = useState(60);

  const startTimer = () => {
    setTimer(--timer)
    const t = setInterval(() => {
      if (timer <= 0) {
        clearInterval(t);
        setTimer(60)
      } else {
        setTimer(--timer)
      }
    }, 1000)
  }

  const getSmsCode = () => {
    const mobile = form.getFieldValue('mobile')
    if (!isPhone(mobile) || timer < 60) return;
    GetSmsApi({
      mobile: mobile,
      userType: '2',
      smsType: 4, // 1: 登录 2: 注册
    }).then((res) => {
      console.log('res:', res);
      if (res.result) {
        message.success(res.data)
        startTimer()
      } else {
        message.error(res.msg)
      }
    })
  }

  useEffect(() => {
    form.resetFields();
    clearSmsError();
  }, [props.open, form])

  const handlePhoneChange = (e) => {
    setPhoneStatus(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(e.target.value))
  }


  return (
    <Form form={form} autoComplete="off" labelCol={{ span: 4 }} name="control-hooks" {...labelControl} >
      <Modal title="绑定新手机" open={props.open} onCancel={props.handleCancel} onOk={handleOk}>
        <Form.Item label="密码" name="password" required rules={[{ required: true, message: '请输入密码' }]}>
          <Input.Password className='user-center-input' placeholder="请输入密码" />
        </Form.Item>
        <Form.Item label="手机号" validateTrigger={['onBlur', 'onChange']} name="mobile" required rules={[{ required: true, pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号', validateTrigger: ['onBlur'] }]}>
          <Input className='user-center-input' placeholder="请输入手机号" onChange={handlePhoneChange} />
        </Form.Item>
        <Form.Item label="验证码" name="verificationCode" required rules={[{ required: true, message: '请输入验证码' }]} {...smsValidProps}>
          <Input className='user-center-input' placeholder="请输入验证码" suffix={<span className={`${!phoneStauts || timer < 60 ? 'getcode-disable' : 'getcode'}`} onClick={getSmsCode}> {timer === 60 ? '获取验证码' : `${timer}s 后重新获取`}   </span>}
          />
        </Form.Item>
      </Modal>
    </Form>
  )
}

function ModifyPassword(props) {
  const [form] = Form.useForm();
  const [activeKey, setActiveKey] = useState('item-1');
  const [loading, setLoading] = useState(false);
  const phone = props.mobile;
  const rule = [
    {
      type: 'string',
      max: 16,
      min: 8,
      required: true,
      pattern: /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$){8,16}/,
      message: '请输入8-16位密码，数字、字母和符号至少包含两种'
    }
  ];

  const checkPwd = (e) => {
    const isSpecial = (a) => {
      let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
      if (reg.test(a)) {
        return true;
      } else {
        return false;
      }
    }
    let s = e;
    if (isSpecial(s)) {
      console.log('密码中允许出现数字、大写字母、小写字母，但至少包含其中3种')
      return '3';
    }
    return '2'
  }

  const handleOk = async () => {
    const e = await form.validateFields();
    if (e.newPassword !== e.newPassword1) return message.error("两次输入的密码不一致，请重新输入");
    setLoading(true)
    if (activeKey === 'item-1') {
      const { result, data, msg } = await UpdatePasswordApi({
        password: e.password,
        newPassword: e.newPassword,
        pwdLevel: checkPwd(e.newPassword)
      });
      if (result) {
        message.success(data)
        props.handleOk(e);
      } else {
        message.error(msg)
      }
    } else {
      ResetPasswordApi({
        password: e.newPassword,
        mobile: phone,
        verificationCode: e.verificationCode,
        pwdLevel: checkPwd(e.newPassword)
      }).then(res => {
        if (res.result) {
          message.success(res.data)
          props.handleOk(e);
        } else {
          message.error(res.msg)
        }
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    form.resetFields();
  }, [props.open, form]);

  let [timer, setTimer] = useState(60);

  const startTimer = () => {
    setTimer(--timer)
    const t = setInterval(() => {
      if (timer <= 0) {
        clearInterval(t);
        setTimer(60)
      } else {
        setTimer(--timer)
      }
    }, 1000)
  }

  const getSmsCode = () => {
    if (timer < 60) return;
    GetSmsApi({
      mobile: phone,
      userType: '2',
      smsType: 3, // 1: 登录 2: 注册 3: 重置
    }).then((res) => {
      if (res.result) {
        message.success(res.data)
        startTimer()
      } else {
        message.error(res.msg)
      }
    })
  }

  const PassWd = (<><Form.Item label="当前账号">
      <span style={{ position: 'relative', top: '4px' }}>{phone}</span>
    </Form.Item>
    <Form.Item label="校验密码" name="password" required rules={[{ required: true, message: '请输入旧密码' }]} getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}>
      <Input.Password className='user-center-input' placeholder="请输入旧密码" />
    </Form.Item>
    <Form.Item label="新密码" name="newPassword" required rules={rule} getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}>
      <Input.Password className='user-center-input' placeholder="请输入新密码" />
    </Form.Item>
    <Form.Item label="确认密码" name="newPassword1" required rules={rule} getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}>
      <Input.Password className='user-center-input' placeholder="请输入新密码" />
    </Form.Item></>
  )

  const ResetPassword = <>
    <Form.Item label="当前账号">
      <span style={{ position: 'relative', top: '4px' }}>{phone}</span>
    </Form.Item>
    
    <Form.Item name="verificationCode" label="验证码"
      rules={[
        {
          required: true,
          message: '请输入短信中的验证码!',
        },
      ]}
    >
      <Input placeholder="请输入验证码" className='user-center-input'
        suffix={<span className={`${styles['text_7']} ${timer < 60 ? 'getcode-disable' : 'getcode'}`} onClick={getSmsCode}> {timer == 60 ? '获取验证码' : `${timer}s 后重新获取`}   </span>} size='small'
      />
    </Form.Item>
    <Form.Item label="新密码" name="newPassword" required rules={rule} getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}>
      <Input.Password className='user-center-input' placeholder="请输入新密码" />
    </Form.Item>
    <Form.Item label="确认密码" name="newPassword1" required rules={rule} getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}>
      <Input.Password className='user-center-input' placeholder="请输入新密码" />
    </Form.Item>
  </>

  return (
      <Modal title="修改密码" open={props.open} onCancel={props.handleCancel} onOk={handleOk} confirmLoading={loading}>
        <Form form={form} autoComplete="off" labelCol={{ span: 4 }} name="control-hooks" {...labelControl} >
          <Tabs destroyInactiveTabPane size={'small'} activeKey={activeKey} onChange={setActiveKey} items={[{ label: '账号校验', key: 'item-1', children: PassWd }, { label: '验证码校验', key: 'item-2', children: ResetPassword }]}>
          </Tabs>
        </Form>
      </Modal>
  )
}

function BindEmail(props) {
  const [form] = Form.useForm();
  const [emailStauts, setEmailStatus] = useState(false);
  const [smsValidProps, setSmsValidProps] = useState({});

  // TODO: 设置验证码的错误状态
  const setSmsError = (val) => {
    setSmsValidProps({
      validateStatus: 'error',
      help: val
    })
  }

  const clearSmsError = () => setSmsValidProps({})

  const handleOk = async () => {
    const e = await form.validateFields();
    const { result, data, msg } = await BindEmaileApi(e);
    if (result) {
      message.success(data)
      props.handleOk(e)
      localStorage.setItem('email', e.email);
    } else {
      message.error(msg)
    }
  }

  let [timer, setTimer] = useState(60);

  const startTimer = () => {
    setTimer(--timer)
    const t = setInterval(() => {
      if (timer <= 0) {
        clearInterval(t);
        setTimer(60)
      } else {
        setTimer(--timer)
      }
    }, 1000)
  }

  const getEmailCode = async (e) => {
    await form.validateFields(['email']);
    const email = form.getFieldValue('email')
    if (timer < 60) return;
    GetEmailApi({
      email: email,
      realName: props.realName,
      userType: '2'
    }).then((res) => {
      if (res.result) {
        message.success(res.data)
        startTimer()
      } else {
        message.error(res.msg)
      }
    })
  }


  const formChange = (e) => {
    form.validateFields(['email'])
      .then(() => setEmailStatus(true))
      .catch(() => setEmailStatus(false))
  }

  useEffect(() => {
    form.resetFields();
    setSmsValidProps({});
  }, [props.open, form])


  return (
    <Form form={form} autoComplete="off" labelCol={{ span: 4 }} name="control-hooks" {...labelControl}>
      <Modal title="绑定新邮箱" open={props.open} onCancel={props.handleCancel} onOk={handleOk}>
        <Form.Item label="密码" name="password" required rules={[{ required: true, message: '请输入密码' }]}>
          <Input.Password className='user-center-input' placeholder="请输入密码" />
        </Form.Item>
        <Form.Item label="邮箱" name="email" onChange={formChange} required rules={[{ required: true, type: 'email', message: '请输入正确的邮箱' }]}>
          <Input className='user-center-input' placeholder="请输入邮箱" />
        </Form.Item>
        <Form.Item label="验证码" name="verificationCode" required rules={[{ required: true, message: '请输入验证码' }]}  {...smsValidProps} >
          <Input className='user-center-input' placeholder="请输入验证码" suffix={<span className={`${!emailStauts || timer < 60 ? 'getcode-disable' : 'getcode'}`} onClick={getEmailCode}> {timer === 60 ? '获取验证码' : `${timer}s 后重新获取`}   </span>}
          />
        </Form.Item>
      </Modal>
    </Form>
  )
}


function UserCenter() {
  const [photoUrl, setPhotoUrl] = useState(null)
  const [bind, setBind] = useState(unbound)
  const [bindDesc, setBindDesc] = useState('未绑定')
  const [userName, setUserName] = useState('')
  const [realName, setRealName] = useState('')
  const [mobile, setMobile] = useState('')
  const [loginTime, setLoginTime] = useState('')
  const [registerTime, setRegisterTime] = useState('')
  const [pwdLevel, setPwdLevel] = useState('')
  const history = useHistory();
  const [showModifyName, setShowModifyName] = useState(false);
  const [showBindPhone, setShowBindPhone] = useState(false);
  const [showModifyPassWord, setShowModifyPassWord] = useState(false);
  const [showBindEmail, setShowBindEmail] = useState(false);
  const [showModifyPhoto, setShowModifyPhoto] = useState(false);
  const [arr, setArr] = useState([])
  const [lastLogin, setLastLogin] = useState({});

  useEffect(() => {
    const searches = new URLSearchParams(window.location.search);
    const token = searches.get('token');
    const id = searches.get('id');
    if (token && id) {
      localStorage.setItem('userToken', token);
      GetUserInfoById(id).then(res => {
        localStorage.setItem('userCode', res.data.id)
        localStorage.setItem('userName', res.data.userName || '')
        localStorage.setItem('realName', res.data.realName || '')
        localStorage.setItem('terminalType', res.data.terminalType)
        localStorage.setItem('userType', res.data.userType)
        localStorage.setItem('mobile', res.data.mobile || '')
        localStorage.setItem('email', res.data.email || '')
        localStorage.setItem('loginWay', res.data.loginWay)
        localStorage.setItem('loginTime', res.data.loginTime)
        localStorage.setItem('photoUrl', res.data.photoUrl || '')
        localStorage.setItem('registerTime', res.data.registerTime)
        localStorage.setItem('pwdLevel', res.data.pwdLevel)
        setMobile(res.data.email);
      })
    }
    GetRecentLoginInfo().then(res => {
      if (res) {
        setLastLogin(res);
      }
    });
  }, [])

  useEffect(() => {
    let userName1 = localStorage.getItem('userName')
    let realName1 = localStorage.getItem('realName')
    let mobile1 = localStorage.getItem('mobile')
    // let email1 = localStorage.getItem('email')
    let loginTime1 = localStorage.getItem('loginTime')
    let registerTime1 = localStorage.getItem('registerTime')
    let photoUrl1 = localStorage.getItem('photoUrl')
    let pwdLevel1 = localStorage.getItem('pwdLevel')
    if (userName1) {
      setUserName(userName1)
    }
    if (realName1) {
      setRealName(realName1)
    }
    if (mobile1) {
      setBindDesc('已绑定')
    }
    if (mobile1) {
      setMobile(mobile1)
    }
    if (loginTime1) {
      setLoginTime(loginTime1)
    }
    if (registerTime1) {
      setRegisterTime(registerTime1)
    }
    if (photoUrl1) {
      setPhotoUrl(photoUrl1)
      setBind(binded)
    } else {
      setPhotoUrl(defaultPhoto)
    }
    if (pwdLevel1) {
      switch (pwdLevel1) {
        case '1':
          setPwdLevel('弱')
          break;
        case '2':
          setPwdLevel('中')
          break;
        case '3':
          setPwdLevel('强')
          break;
        default:
          setPwdLevel()
          break;
      }
      GetProductByUserIdApi(
      ).then((res) => {
        console.log(res)
        if (res.result) {
          let newArr = undefined
          if (res.data === null) {
            newArr = null
          } else {
            // console.log(res.data)
            newArr = JSON.parse(
              JSON.stringify(res.data)
            )
          }
          setArr(newArr)
          // console.log(newArr)
        }
      })
    }

  }, [userName, realName, mobile, loginTime, registerTime, photoUrl, pwdLevel])


  const logout = () => {
    LogoutApi().then((res) => {
      console.log('res:', res);
      if (res?.result) {
        message.success(res.data)
        //清空用户登录数据
        localStorage.clear()
        //跳转到账号登录页面页面
        setTimeout(() => {
          history.push('/accountLogin')
        }, 1500);
      } else {
        message.error(res.msg)
        //清空用户登录数据
        localStorage.clear()
        //跳转到账号登录页面页面
        setTimeout(() => {
          history.push('/accountLogin')
        }, 1500);
      }
    })
  }

  const toFj = (e) => {
    // alert(e)
    let userToken = localStorage.getItem('userToken')
    let userCode = localStorage.getItem('userCode')
    let url = e + '?userToken=' + userToken + '&userCode=' + userCode;
    // alert(url)
    const w = window.open('_black')
    w.location.href = url
  }
  return (
    <div className={`${styles.page} flex-col user-center`}>
      <div className={`${styles['group_1']} flex-col`}>
        <div className={`${styles['box_1']} flex-row`}>
          <img alt=''
            className={`${styles['image_1']}`}
            src={
              require('./assets/img/SketchPng6d2397ce608005b4b9ed049216c5aaabf2ac9100f3f3d3b668a1d5274a3e5b17.png')
            }
          />
          <div className="box_2 flex-col" />
          <span className={`${styles['text_1']}`}>用户中心</span>
          <span className={`${styles['text_2']}`}>{userName}</span>
          <div className={`${styles['box_3']} flex-col`} onClick={logout}>退出登录</div>
        </div>
      </div>
      <div className={`${styles['group_13']} flex-col`}>
        <span className={`${styles['text_3']}`}>我的产品</span>
        <div className={`${styles['section_11']} flex-row`}>
          {
            arr.map(item => (
              <div key={item.id} className={`${styles['group_3']} flex-col`} style={{ background: `url(${item.photoUrl})  no-repeat` }}>
                <div className={`${styles['text-wrapper_1']} flex-col`}>
                  <span className={`${styles['text_4']}`}>
                    <button onClick={() => { toFj(item.productUrl) }}> {item.productName} </button>
                  </span>
                </div>
              </div>
            ))
          }
        </div>
        <span className={`${styles['text_8']}`}>基本信息</span>
        <div className={`${styles['section_2']} flex-row`}>
          <div className={`${styles['group_7']} flex-col`} onClick={() => setShowModifyPhoto(true)} style={{ cursor: 'pointer' }}>
            <img alt=''
              src={photoUrl ? photoUrl : require('./assets/img/header.jpg')} style={{ 'width': '80px', 'height': '80px', cursor: 'pointer' }}
            />
          </div>
          <div className={`${styles['block_2']} flex-col justify-between`}>
            <div className={`${styles['text-wrapper_7']} flex-row`}>
              <span className={`${styles['text_9']}`}>姓名：</span>
              <span className={`${styles['text_10']}`}>{realName}</span>
              <span className={`${styles['text_11']}`}>
                <button className={`${styles['text_11']}`} onClick={() => setShowModifyName(true)}>修改</button>
              </span>
            </div>
            <div className={`${styles['text-wrapper_7']} flex-row`}>
              <span className={`${styles['text_9']}`}>手机：</span>
              <span className={`${styles['text_10']}`} style={{ 'width': '70px' }}>{mobile}</span>
              <span className={`${styles['text_11']}`}>
                <button className={`${styles['text_11']}`} onClick={() => setShowBindPhone(true)}>绑定手机号</button>
              </span>
            </div>
          </div>
        </div>
        <div className={`${styles['section_3']} flex-col`} />
        <div className={`${styles['section_4']} flex-row justify-between`}>
          <div className={`${styles['block_3']} flex-col justify-between`}>
            <span className={`${styles['text_15']}`}>密码</span>
            <div className={`${styles['image-text_5']} flex-row justify-between`}>
              <div className={`${styles['box_4']} flex-col`} />
              <span className={`${styles['text-group_1']}`}>当前密码强度：{pwdLevel}</span>
            </div>
          </div>
          <span className={`${styles['text_16']}`}>
            <button className={`${styles['text_16']}`} onClick={() => setShowModifyPassWord(true)}>修改密码</button>
          </span>
        </div>
        <div className={`${styles['section_5']} flex-col`} />
        <div className={`${styles['section_6']} flex-row justify-between`}>
          <div className={`${styles['group_14']} flex-col justify-between`}>
            <span className={`${styles['text_17']}`}>邮箱</span>
            <div className={`${styles['image-text_6']} flex-row justify-between`}>
              <div className={`${styles['group_10']} flex-col`} >
                <img alt=''
                  src={bind}
                />
              </div>
              <span className={`${styles['text-group_2']}`}>{lastLogin.email ? `已绑定 (${lastLogin.email})` : '未绑定'}</span>
            </div>
          </div>
          <span className={`${styles['text_16']}`} onClick={() => setShowBindEmail(true)} style={{
            'width': '56px',
            'height': '20px',
            'overflowWrap': 'break-word',
            'color': '#3370ff',
            'fontSize': '14px',
            'fontFamily': 'PingFangSC-Regular',
            'textAlign': 'left',
            'whiteSpace': 'nowrap',
            'lineHeight': '20px',
            'marginTop': '30px'
          }}>
            绑定邮箱
          </span>
        </div>
        {/* <div className={`${styles['section_7']} flex-col`} /> */}
        {/* <div className={`${styles['section_8']} flex-row justify-between`}> */}
        {/* <div className={`${styles['group_15']} flex-col justify-between`}> */}
        {/* <span className={`${styles['text_19']}`}>微信</span> */}
        {/* <div className={`${styles['image-text_7']} flex-row justify-between`}> */}
        {/* <div className={`${styles['group_12']} flex-col`} /> */}
        {/* <span className={`${styles['text-group_3']}`}>未绑定</span> */}
        {/* </div> */}
        {/* </div> */}
        {/* <span className={`${styles['text_20']}`}>绑定微信</span> */}
        {/* </div> */}
        <div className={`${styles['section_9']} flex-col`} />
        <div className={`${styles['section_12']} flex-row`}>
          <div className={`${styles['image-text_8']} flex-row justify-between`}>
            <div className={`${styles['box_5']} flex-col`} />
            <span className={`${styles['text-group_4']}`}>注册时间：{lastLogin.registerTimeStr}</span>
          </div>
          <span className={`${styles['text_21']}`}>最近登录: {lastLogin.loginTimeStr}</span>
          {/* <span className={`${styles['text_22']}`}>更多</span> */}
        </div>
        <div className={`${styles['text-group_6']} flex-col justify-between`}>
          <span className={`${styles['text_23']}`}>
            <a href='http://www.rmes.cn/' target="_blank">关于我们</a>&nbsp;&nbsp;&nbsp;&nbsp;｜&nbsp;&nbsp;&nbsp;&nbsp;<a href='mailto:service@rmes.cn' target="_blank">联系我们</a>&nbsp;&nbsp;&nbsp;&nbsp;｜&nbsp;&nbsp;&nbsp;&nbsp;<a href='/html/agreement.html' target="_blank">用户协议</a>&nbsp;&nbsp;&nbsp;&nbsp;｜&nbsp;&nbsp;&nbsp;&nbsp;<a href='/html/privacy.html' target="_blank">隐私政策</a>
          </span>
          <span className={`${styles['text_24']}`}>@RMES Company Limited 2018-2021 All Rights Reserved. 北京迈思发展科技有限责任公司 京ICP备14009982号-3</span>
        </div>
      </div>
      <ModifyUserNew open={showModifyName} realName={realName} handleCancel={() => setShowModifyName(false)} handleOk={(e) => { setRealName(e.realName); setShowModifyName(false); }} />
      <BindPhone open={showBindPhone} handleCancel={() => setShowBindPhone(false)} handleOk={(e) => { setMobile(e.mobile); setShowBindPhone(false); }} />
      <BindEmail realName={realName} open={showBindEmail} handleCancel={() => setShowBindEmail(false)} handleOk={(e) => { setMobile(e.mobile); setShowBindEmail(false); }} />
      <ModifyPassword mobile={mobile} open={showModifyPassWord} handleCancel={() => setShowModifyPassWord(false)} handleOk={(e) => { setMobile(e.mobile); setShowModifyPassWord(false); }} />
      {/* <ModifyPhoto src={photoUrl} open={showModifyPhoto} handleCancel={() => setShowModifyPhoto(false)} handleOk={(e) => {setPhotoUrl(e);setShowModifyPhoto(false)}}  ></ModifyPhoto> */}
      <ModifyPhotoNew open={showModifyPhoto} handleCancel={() => setShowModifyPhoto(false)} handleOk={(e) => { setPhotoUrl(e); setShowModifyPhoto(false) }}></ModifyPhotoNew>
    </div>
  );
}
export default UserCenter;
